<h3>Produkte</h3>

<p>Wir bieten Naturfederbälle hyce Gold und hyce Silver an. Diese können in den Geschwindigkeiten 76 und 77 über uns bezogen werden. In einer Rollen befinden sich 12 Federbälle.</p>

<div class="d-flex flex-row bd-highlight mb-3" style="border-radius: 25px; border: 2px solid rgb(247, 247, 249); margin: 10px; padding: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
  <div class="p-2 bd-highlight">
    <p><b>hyce Gold (Geschwindigkeit 76 und 77)</b></p>
    <ul>
      <li>1 Rolle jeweils 20,00 €</li>
      <li>ab 10 Rolle jeweils 19,50 €</li>
      <li>ab 50 Rolle jeweils 18,60 €</li>
    </ul>
    <p>(Brutto Preise)</p>
  </div>
  <div class="p-2 bd-highlight">
    <img class="mx-auto d-block w-50" src="..\..\assets\webphotos\roll-gold-800.png" alt="gold" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 25px;">
  </div>
</div>


<div class="d-flex flex-row bd-highlight mb-3" style="border-radius: 25px; border: 2px solid rgb(247, 247, 249); margin: 10px; padding: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
  <div class="p-2 bd-highlight">
    <p><b>hyce Silver (Geschwindigkeit 76 und 77)</b></p>
    <ul>
      <li>1 Rolle jeweils 17,50 €</li>
      <li>ab 10 Rolle jeweils 17,10 €</li>
      <li>ab 50 Rolle jeweils 16,30 €</li>
    </ul>
    <p>(Brutto Preise)</p>
  </div>
  <div class="p-2 bd-highlight">
    <img class="mx-auto d-block w-50" src="..\..\assets\webphotos\roll-silver-800.png" alt="silver" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 25px;">
  </div>
</div>

<br>

<p>Sollten Sie größere Mengen abnehmen, unterbreiten wir Ihnen gerne ein individuell erstelltes Angebot. Sie sind Händler? Kontaktieren Sie uns gerne (<a href="mailto:contact@hyce.de">contact@hyce.de</a>) zu unserem kommissionsgeschäft für Händlerangebote. Nähere Informationen und Testangebote für Vereine erhalten Sie <a routerLink="/about" routerLinkActive="active">HIER</a>.</p>
