<div id="wrapper" style="height: 100vh;">

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
          <!-- Topbar -->
          <div class="navbar-header">
            <div>
              <app-header></app-header>
            </div>
          </div>
          <!-- End of Topbar -->

          <div class="d-flex justify-content-center" style="background-color: rgb(247, 247, 249);">
            <!-- Begin Page Content -->
            <div class="page-header" style="
              max-width: 1200px;
              min-height: 100vh;
              background-color: white;
              padding: 3%;
              padding-bottom: 4%;">
              <router-outlet></router-outlet>
            </div>
            <!-- /.container-fluid -->
          </div>

      </div>
      <!-- End of Main Content -->
      <!-- Footer -->
      <div class="page-fooder fooder-expand-sm" style="
        bottom: 0;
        width: 100%;">
        <app-footer></app-footer>
      <!-- End of Footer -->
      </div>
  </div>
  <!-- End of Content Wrapper -->
