<div id="carouselIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselEIndicators" data-slide-to="1"></li>
    <li data-target="#carouselIndicators" data-slide-to="2"></li>
  </ol>

  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="..\..\assets\webphotos\rolls-both-start-800.png" alt="rolls">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="..\..\assets\webphotos\roll-gold-800.png" alt="gold">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="..\..\assets\webphotos\roll-silver-800.png" alt="silver">
    </div>
  </div>

  <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

<h3>Willkommen bei hyce!</h3>

<p>Bei uns gibt es die exzellenten hyce Gold Premium und Silver Naturfederbälle mit der Geschwindigkeit 76 und 77, für ein wunderbares Badmintonerlebnis. </p> <p> Geeignet für den Badmintonspielbetrieb.</p>
