<nav class="navbar navbar-expand-sm navbar-dark bg-dark justify-content-center">
  <a class="navbar-brand" routerLink="">
    <img src="..\..\assets\logo\logo_weiss_web_svg.svg" class="img-fluid" alt="hyce" style="width:100px">
  </a>

  <button class="navbar-toggler" type="button"
          (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse"
       [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item">
        <a class="nav-link" routerLink="/" routerLinkActive="active"><b>Willkommen</b></a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/products" routerLinkActive="active">Produkte</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/order" routerLinkActive="active">Bestellung</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/approval" routerLinkActive="active">Ballzulassung</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/contact" routerLinkActive="active">Kontakt</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/about" routerLinkActive="active">Über uns</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/impress" routerLinkActive="active">Impressum</a>
      </li>

    </ul>
  </div>
</nav>
