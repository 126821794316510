import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { OrderComponent } from './order/order.component';
import { BallapprovalComponent } from './ballapproval/ballapproval.component';
import { DeMapApprovalComponent } from './icon/de-map-approval/de-map-approval.component';
import { InfoBoardComponent } from './ballapproval/info-board/info-board.component';
import { BallapprovalService } from './ballapproval/ballapproval.service';
import { ImpressComponent } from './impress/impress.component';
import { ProductsComponent } from './products/products.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    OrderComponent,
    BallapprovalComponent,
    DeMapApprovalComponent,
    InfoBoardComponent,
    ImpressComponent,
    ProductsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [
    BallapprovalService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
