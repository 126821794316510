import { Injectable, Input } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BallapprovalService {
  infoIsVisible: boolean = false;
  sendStateName: string;


  constructor() { }

  // public showTooltip(event) {
  //   if (event.target.parentElement.id) {
  //     console.log("Tooltip: " + event.target.parentElement.attributes.name.nodeValue);
  //   }
  // }

  public setInfoToVisible(event) {
    if (event.target.parentElement.id) {
      this.infoIsVisible = true;
    }
    console.log("showInfo method =" + this.infoIsVisible);
    return this.infoIsVisible;
  }

  public stateName(event) {
    if (event.target.parentElement.id) {
      this.sendStateName = event.target.parentElement.attributes.name.nodeValue;
    }
    console.log("stateName method =" + this.sendStateName);
    return this.sendStateName;
  }

  public get showService(): boolean {
    return this.infoIsVisible;
  }

  public get showStateName(): string {
    return this.sendStateName;
  }

}
