
<!--
  <p># show bool = {{ showInfoBool }}</p>
  <p># click</p>
-->

<div *ngIf="!showInfoBool" style="border-radius: 25px; border: 2px solid rgb(247, 247, 249); margin: 10px; padding: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
  <ul>
    <li>Badminton-Verband Sachsen (BVS)</li>
    <li>Bayerischer Badminton-Verband (BBV)</li>
    <li>Niedersächsischer Badminton-Verband (NBV)</li>
    <li>Bremer Badminton-Verband (DBV)</li>
    <li>Badminton-Landesverband Sachsen-Anhalt (BLSA)</li>
    <li>Thüringer Badminton Verband e.V. (TBV)</li>
  </ul>
</div>

<ul *ngIf="showInfoBool" class="list-group list-group-flush">
  <li class="list-group-item"><b>{{ dataStateName }}</b></li>
  <li class="list-group-item">Verband: {{ dataAssociationName }}</li>
  <li class="list-group-item">Verbandsaddresse: {{ dataAssociationLink }}</li>
  <li class="list-group-item">Spielberechtigung: {{ dataProduct1 }}({{ dataClassify1 }}), {{ dataProduct2 }}({{ dataClassify2 }})</li>
</ul>
