<div>
  <p class="h3">hyce GmbH</p>

  <p class="text-justify">Ansbacher Straße 25, 04207 Leipzig, Deutschland</p>

  <p class="text-justify">Telefon (Ansprechpartner: Francesco Hanke): 0176/56801769</p>

  <p class="text-justify">eMail: <a href="mailto:contact@hyce.de">contact@hyce.de</a></p>

  <p class="text-justify">Handelsregister: (HRB: 37140) Amtsgericht Leipzig </p>
</div>


<div class="">
  <img class="mx-auto d-block w-50" src="..\..\assets\webphotos\shuttle-shadow-800.png" alt="">
</div>
