import { Component, Injectable, ElementRef, HostBinding, HostListener, OnInit, Renderer2 } from '@angular/core';

import { BallapprovalService } from "../../ballapproval/ballapproval.service";

@Component({
  selector: 'app-de-map-approval',
  templateUrl: './de-map-approval.svg',
  styleUrls: ['./de-map-approval.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class DeMapApprovalComponent implements OnInit {

  constructor(private ballapprovalService: BallapprovalService) {}

  // @HostListener('mouseover', ["$event"]) onHover(event: any) {
  //   this.ballapprovalService.showTooltip(event);
  // }

  @HostListener('click', ["$event"]) onClick(event: any) {
    this.ballapprovalService.setInfoToVisible(event);
    this.ballapprovalService.stateName(event)
  }

  ngOnInit(): void {
  }

}
