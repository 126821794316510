<h3>Über uns:</h3>

<ul>
  <li>mit hyce als Partner, haben Sie eine in den Landesverbänden stark wachsende Marke</li>
  <li>Unsere Federbälle haben eine ausgezeichnete Flugeigenschaft und eine hohe Haltbarkeit</li>
  <li>zugelassen für den offiziellen Spielbetrieb (u.a. im BVS, Deutschlandweite Zulassungen sind ab der Season 21/22 geplant)</li>
  <li>Bekanntheit durch Onlinevertrieb (Präsenz auf Onlineplattformen, u.a. bei Amazon unter den Top 5 Produkten bei „Naturfederbällen“)</li>
  <li>Europaweiter Vertrieb</li>
</ul>

<p>Information für Händler: Geschäft auf Komissionsbasis</p>
<ul>
  <li>Sie bezahlen nur die verkauften Produkte</li>
  <li>keine Kapitalbindung</li>
  <li>monatliche rückwirkend Abrechnung möglich</li>
  <li>wir versorgen Sie rechtzeitig mit neuen Artikeln (versandkostenfrei in Deutschland)</li>
</ul>

<p>Mehrwert für Ihre Kunden und für Vereine:</p>
<ul>
  <li>attraktive Preisepolitik</li>
  <li>Mengenrabatte bereits ab 10 Rollen möglich</li>
  <li>hohe Rabatte für Abnahmen in Vereinsgrößen (ab 50 Rollen)</li>
</ul>

<p>Für Vereine haben wir folgende einmalige kennenlern Angebote zum Testen unserer Rollen (pro Verein max. 1 Bestellung):</p>

<div class="d-flex flex-column bd-highlight mb-3" style="border-radius: 25px; border: 2px solid rgb(247, 247, 249); margin: 10px; padding: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
  <div class="p-2 bd-highlight">
    <p><b>Kleine Testpaket</b></p>
    <ul>
      <li>hyce Gold (Geschwindigkeit 76)</li>
      <li>hyce Gold (Geschwindigkeit 77)</li>
    </ul>
</div>
<div class="p-2 bd-highlight">
    <p>jeweils 1 Rolle zum unschlagbaren Gesamtpreis von 30,00 € (brutto, versandkostenfrei)</p>
  </div>
</div>


<div class="d-flex flex-column bd-highlight mb-3" style="border-radius: 25px; border: 2px solid rgb(247, 247, 249); margin: 10px; padding: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
  <div class="p-2 bd-highlight">
    <p><b>Große Testpaket</b></p>
    <ul>
      <li>hyce Gold (Geschwindigkeit 76)</li>
      <li>hyce Gold (Geschwindigkeit 77)</li>
      <li>hyce Silver (Geschwindigkeit 76)</li>
      <li>hyce Silver (Geschwindigkeit 77)</li>
    </ul>
  </div>
  <div class="p-2 bd-highlight">
    <p>jeweils 1 Rolle zum unschlagbaren Gesamtpreis von 60,00 € (brutto, versandkostenfrei)</p>
  </div>
</div>
