import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BallapprovalComponent } from './ballapproval/ballapproval.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ImpressComponent } from './impress/impress.component';
import { OrderComponent } from './order/order.component';
import { ProductsComponent } from './products/products.component';


const routes: Routes = [
  { path: '', component:  HomeComponent },
  { path: 'about', component:  AboutComponent },
  { path: 'contact', component:  ContactComponent},
  { path: 'order', component:  OrderComponent },
  { path: 'products', component:  ProductsComponent },
  { path: 'approval', component: BallapprovalComponent},
  { path: 'impress', component:  ImpressComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
