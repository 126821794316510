<div>
  <h3>Ballzulassung</h3>

  <p>Deutschlandweite Zulassungskarte der hyce Federbälle für den Ligaspielbetrieb.</p>
</div>
<div class="col-sm-12">

  <app-de-map-approval class="img-fluid"></app-de-map-approval>

</div>
<div class="col-sm-12">
    <app-info-board></app-info-board>
</div>

