import { Component, HostListener, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as deMapApprovalData from './map_tooltip_data.json';

import { BallapprovalService } from "../../ballapproval/ballapproval.service";
import { BallapprovalComponent } from '../ballapproval.component';
@Component({
  selector: 'app-info-board',
  templateUrl: './info-board.component.html',
  styleUrls: ['./info-board.component.scss']
})
export class InfoBoardComponent implements OnInit {
  @ViewChildren(BallapprovalComponent) private stateName: QueryList<BallapprovalComponent>;
  
  dataStateName: string;
  dataAssociationName: string;
  dataAssociationLink: string;
  dataProduct1: string;
  dataClassify1: string;
  dataProduct2: string;
  dataClassify2: string;
  showInfoBool: boolean;

  constructor(private ballapprovalService: BallapprovalService) {
    this.showInfoBool = ballapprovalService.showService;
    this.dataStateName = ballapprovalService.showStateName;
    console.log("infoboard construcor");

    console.log("this.showInfoBool = " + this.showInfoBool);
    console.log("this.dataStateName = " + this.dataStateName);
  }

  ngOnInit(): void {
    console.log("infoboard Init");
  }

}

